import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { toast, ToastOptions, TypeOptions } from "react-toastify";

type ToastProps = {
  type: string;
  message: string | JSX.Element;
};

export const Toast = ({ type, message }: ToastProps) => {
  let classNameVar = "";
  let progressClassNameVar = "";
  let iconVar;
  if (type === "success") {
    classNameVar =
      "bg-green-200 p-2 rounded-md shadow-md w-[24rem] right-20 z-[30000]";
    progressClassNameVar = "bg-green-800";
    iconVar = (
      <div className="flex items-center justify-center w-10 h-10">
        <CheckCircleIcon className="text-green-800" aria-hidden="true" />
      </div>
    );
  } else if (type === "error") {
    classNameVar =
      "bg-orange-200 p-2 rounded-md shadow-md w-[24rem] right-20 z-[30000]";
    progressClassNameVar = "bg-orange-800";
    iconVar = (
      <div className="flex items-center justify-center w-10 h-10">
        <ExclamationTriangleIcon
          className="text-orange-800"
          aria-hidden="true"
        />
      </div>
    );
  } else if (type === "info") {
    classNameVar =
      "bg-brown-200 p-2 rounded-md shadow-md w-[24rem] right-20 z-[30000]";
    progressClassNameVar = "bg-brown-800";
    iconVar = (
      <div className="flex items-center justify-center w-10 h-10">
        <InformationCircleIcon className="text-brown-800" aria-hidden="true" />
      </div>
    );
  }
  const parameters: ToastOptions = {
    type: type as TypeOptions,
    className: classNameVar,
    progressClassName: progressClassNameVar,
    bodyClassName: "text-brown-800 my-1",
    position: toast.POSITION.TOP_RIGHT,
    icon: iconVar,
  };
  if (message && message !== "") {
    toast(message, parameters);
  }
};

export default Toast;
