var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"kvbdXInABDr2693q7gKRx"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/** @format */

import * as Sentry from "@sentry/nextjs";

const { APP_ENV } = process.env;
const SENTRY_DSN: string = process.env.NEXT_PUBLIC_SENTRY_DSN_WWW;

console.warn("www Sentry client => ", APP_ENV);

Sentry.init({
  dsn: SENTRY_DSN,
  environment: APP_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.25,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
